<template>
  <div>
    <el-tiptap
      @onInit="onInit"
      :readonly="!isAllowedPrint"
      :editable="false"
      height="100%"
      width="100%"
      :extensions="extensions"
      :tooltip="false"
      :showMenubar="false"
      :content="content"
    >
    </el-tiptap>
  </div>
</template>

<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Image,
  Strike,
  Underline,
  Link,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  LineHeight,
  TrailingNode,
  Table, // use with TableHeader, TableCell, TableRow
  TableHeader,
  TableCell,
  TableRow,
  TextColor,
  FontSize,
  TextHighlight,
  Preview,
  Fullscreen,
  Print
} from "element-tiptap";

export default {
  name: "EditorViewer",
  props: {
    isPvObject: {
      type: Object,
      required: true
    },
    isAllowedPrint: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    extensions: [
      new Doc(),
      new Text(),
      new Paragraph(),
      new Heading({ level: 5 }),
      new Bold(),
      new Underline(),
      new Italic(),
      new Strike(),
      new Link(),
      new TextAlign(),
      new Image({ menubar: false }),
      new ListItem(),
      new FontSize({
        fontSizes: ["8", "10", "12", "14", "16", "18", "20", "24", "30", "36", "48"]
      }),
      new LineHeight(),
      new TrailingNode(),
      new Table({
        resizable: true
      }),
      new TableHeader(),
      new TableCell(),
      new TableRow(),
      new TextColor(),
      new TextHighlight(),
      new Preview(),
      new BulletList(),
      new OrderedList(),
      new TodoItem(),
      new TodoList(),
      new HardBreak(),
      new HorizontalRule(),
      new Fullscreen(),
      new History(),
      new Print({ bubble: true })
    ],
    content: ``
  }),
  methods: {
    onInit() {
      this.content = this.isPvObject.file_blob;
    }
  },
  watch: {
    isPvObject: function(val, oldVal) {
      // console.log("new: %s, old: %s", val.file_blob, oldVal.file_blob);
      this.content = val.file_blob;
    }
  },
  created() {
    // alert('cr');
    this.content = "";
  }
};
</script>
<style lang="scss">
.el-tiptap-editor {
  .el-tiptap-editor__content {
    height: 100vh;
  }
}
/* Basic editor styles */
.ProseMirror {
  margin: 1rem 0;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

/* Table-specific styling */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
