<template>
  <v-container fluid>
    <section v-if="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.notice">
      <v-row>
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title><h4>Mon Profile</h4></v-card-title>
            <v-card-text>
              <v-divider class="mb-2"></v-divider>
              <b>Email :</b>
              {{ this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.repEmail }}
              <v-spacer class="my-1"></v-spacer>
              <b>Téléphone :</b>
              {{ this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.repPhone }}
              <v-spacer class="my-1"></v-spacer>
              <b>Lien de visioconference :</b>
              {{ this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].session.link }}
              <v-spacer class="my-1"></v-spacer>
            </v-card-text>
          </v-card>
          <v-card
            class="mt-2"
            v-if="!this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].procuration_state"
          >
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <h4 class="h6" v-bind="attrs" v-on="on">Lettre de procuration</h4>
                </template>
                <span>Lettre de procuration</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <span>
                Rattaché le fichier pour l'envoie de la lettre de procuration
              </span>
              <v-file-input
                v-model="proc.file"
                multiple
                label="Inserer la lettre de procuration"
                truncate-length="15"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="addProcuration()" class="white--text" block>
                <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>Soumettre
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card
            class="mt-1"
            v-if="!this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].signature_state"
          >
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <h4 class="h4" v-bind="attrs" v-on="on">Signature du proces verbal</h4>
                </template>
                <span>Signature du proces verbal</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="undo" class="white--text" icon>
                <v-icon>
                  mdi-backup-restore
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
            <v-text-field
              v-model="rep.name"
              name="input"
              class="mb-2"
              type="text"
              label="Votre Nom"
            ></v-text-field>
            <v-text-field
              v-model="rep.surname"
              name="input"
              type="text"
              class="mb-2"
              label="Votre Prenom"
            ></v-text-field>
            <v-text-field
              v-model="rep.function"
              name="input"
              type="text"
              class="mb-2"
              label="Votre Fonction"
            ></v-text-field>
              <VueSignaturePad
                class="mx-auto"
                style="border:1px solid red"
                height="120px"
                width="100%"
                :options="{ penColor: 'rgba(0,43,89)', minWidth: 0.2, maxWidth: 1.5 }"
                ref="signaturePad"
              />
              <div></div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="save" class="white--text" block>
                <v-icon class="mr-2" dark>mdi-draw</v-icon>Signer la liste de présence
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="7">
          <v-card class="mb-2">
            <v-card-title>
              <h4>Avis d'appel a concurrence</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-list-item class="block text-truncate">
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text text-truncate font-weight-bold"
                      v-text="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.notice.object"
                    ></v-list-item-title>

                    <v-list-item-subtitle class="text-truncate secondary--text">{{
                      this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.notice.publiDate
                        | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="!this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].pv">
            <v-card-title>
              <h4>
                Aperçu de la reponse a l'
                {{
                  this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.notice.noticeType
                    .description
                }}
              </h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height:62em !important;overflow:hidden">
              <VueDocPreview
                style="height:100%"
                v-if="
                  this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'doc' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'docx' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'xls' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'xlsx' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'XLS' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'XLSX'
                "
                :value="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileLink"
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'pdf'"
                :src="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileLink"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-card-text>
          </v-card>
          <v-card v-if="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].pv">
            <v-card-title>
              <h4>
                Le Procès-Verbal (PV) de la seance
              </h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text >
              <EditorViewer :isPvObject="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].pv"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-if="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.document">
      <v-row>
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title><h4>Mes informations en tant que représentant du soumissionnaire</h4></v-card-title>
            <v-card-text>
              <v-divider class="mb-2"></v-divider>
              <b>Nom du représentant du soumissionnaire :</b>
              {{ this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.repFirstname }}
              <v-spacer class="my-1"></v-spacer>
              <b>Prénoms du représentant du soumissionnaire :</b>
              {{ this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.repLastname }}
              <v-spacer class="my-1"></v-spacer>
              <b>E-mail du représentant du soumissionnaire :</b>
              {{ this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.repEmail }}
              <v-spacer class="my-1"></v-spacer>
              <b>Numéro de téléphone du représentant du soumissionnaire :</b>
              {{ this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.repPhone }}
              <v-spacer class="my-1"></v-spacer>
              <b>Lien de la visioconférence pour accéder à la séance publique d’ouverture :</b>
              {{ this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].session.link }}
              <v-spacer class="my-1"></v-spacer>
            </v-card-text>
          </v-card>
          <v-card
            class="mt-2"
            v-if="!this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].procuration_state"
          >
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <h4 class="h6" v-bind="attrs" v-on="on">Transmission de la lettre de procuration/désignation du représentant du soumissionnaire</h4>
                </template>
                <span>Transmission de la lettre de procuration/désignation du représentant du soumissionnaire</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <span>
                Lettre/le courrier de procuration et envoyer à l’Acheteur / Autorité Contractante
              </span>
              <v-file-input
                v-model="proc.file"
                multiple
                label="Joindre la lettre/ le courrier de procuration"
                truncate-length="15"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="addProcuration()" class="white--text" block>
                <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>ENVOYER LE DOCUMENT
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card
            class="mt-1"
            v-if="!this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].signature_state"
          >
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <h4 class="h4" v-bind="attrs" v-on="on">Cadre pour la signature du Procès-Verbal par le représentant du soumissionnaire</h4>
                </template>
                <span>Cadre pour la signature du Procès-Verbal par le représentant du soumissionnaire</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="undo" class="white--text" icon>
                <v-icon>
                  mdi-backup-restore
                </v-icon>Reprendre la signature 
              </v-btn>
            </v-card-title>
            <v-card-text>
              <VueSignaturePad
                class="mx-auto"
                style="border:1px solid red"
                height="120px"
                width="100%"
                :options="{ penColor: 'rgba(0,43,89)', minWidth: 0.2, maxWidth: 1.5 }"
                ref="signaturePad"
              />
              <div></div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="save" class="white--text" block>
                <v-icon class="mr-2" dark>mdi-draw</v-icon>VALIDER LA SIGNATURE
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="7">
          <v-card class="mb-2">
            <v-card-title>
              <h4>Dossier concerné</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-list-item class="block text-truncate">
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text text-truncate font-weight-bold"
                      v-text="
                        this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.document.object
                      "
                    ></v-list-item-title>

                    <v-list-item-subtitle class="text-truncate secondary--text">{{
                      this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.document.publiDate
                        | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="!this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].pv">
            <v-card-title>
              <h4>
                <!--Aperçu de mon Offre/Proposition/Pli
                {{
                  this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.document.documentType
                    .description
                }}
                -->
                Aperçu de l'offre/proposition/pli
              </h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height:62em !important;overflow:hidden">
              <VueDocPreview
                style="height:100%"
                v-if="
                  this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'doc' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'docx' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'xls' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'xlsx' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'XLS' ||
                    this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'XLSX'
                "
                :value="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileLink"
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileType == 'pdf'"
                :src="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.fileLink"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-card-text>
          </v-card>
          <v-card v-if="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].pv">
            <v-card-title>
              <h4>
                Le Procès-Verbal (PV) de la seance
              </h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text >
              <EditorViewer :isPvObject="this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].pv"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";
import EditorViewer from "../../../config/EditorViewer";
import { mapActions } from "vuex";
import VueDocPreview from "vue-doc-preview";

export default {
  components: { DatePicker, EditorViewer, VueDocPreview },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    rep:{
      name:'',
      function:'',
      surname:''
    },
    signed: false,
    proc: {
      file: []
    }
  }),
  computed: {
    getRemaining() {
      if (this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.document) {
        return moment(
          this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.document.date_opening
        ).from(new Date());
      }
      if (this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.notice) {
        return moment(this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.notice.deadline).from(
          new Date()
        );
      }
    }
  },
  created() {
    const data = new FormData();
    data.append("token", this.id);
    this.representatorCheck(data);
  },
  methods: {
    ...mapActions({
      representatorCheck: "Auth/representatorCheck"
    }),
    addProcuration() {
      const data = new FormData();
      data.append("session", this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].session.id);
      data.append("file", this.proc.file[0]);
      data.append("rep_email", this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].offer.repEmail);
      this.$store.dispatch("Auth/addProcuration", data).then(() => {
        const data = new FormData();
        data.append("token", this.id);
        this.representatorCheck(data);
      });
      this.proc.file = [];
    },
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      const dataSign = new FormData();
      dataSign.append("file_blob", data);
      dataSign.append("session_id", this.$store.getters["Auth/GET_PUBLIC_REP_OFFER"].session.id);
      // PROFILE SIGNING
      dataSign.append("is_rep", 1);
      dataSign.append("is_op", 0);
      dataSign.append("is_com", 0);
      // DATA TO SEND
      if(this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.notice){

        dataSign.append("name", this.rep.name);
        dataSign.append("surname", this.rep.surname);
        dataSign.append("fonction", this.rep.function);
      }else{

        dataSign.append("name", this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.repFirstname);
        dataSign.append("surname", this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.repLastname);
        dataSign.append("fonction", this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.repTitle);
      }

      dataSign.append("email", this.$store.getters['Auth/GET_PUBLIC_REP_OFFER'].offer.repEmail);
      dataSign.append("quality", "Representant");

      if (!isEmpty) {
        this.$store.dispatch("Auth/addSignature", dataSign).then(() => {
          const data = new FormData();
          data.append("token", this.id);
          this.representatorCheck(data);
        });
      } else {
        //alert swal sign empty
      }
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
