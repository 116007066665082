<template>
  <v-container fluid>
    <v-container>
      <v-card flat>
        <v-card-title class="primary--text"
        :class="{'headline': $vuetify.breakpoint.smAndDown, 'display-1': $vuetify.breakpoint.mdAndUp}"
          >Page d’accès temporaire du représentant du soumissionnaire pour la participation à la séance publique d’ouverture (Offres/ Propositions/ Plis)</v-card-title
        >
        <hr class="primary" />
        <v-card-text>
          <ValidationObserver ref="observer">
            <form>
              <v-row class="mx-auto my-5 flex align-center">
                <v-col cols="12" md="5" class="px-0">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title>Notez Bien (NB) :</v-list-item-title>
                      <v-divider class="my-2"></v-divider>
                      <v-list-item-title
                      class="text-wrap">
                         Après avoir reçu le mail d’information concernant votre accès.
                      </v-list-item-title>
                      <v-list-item-title
                      class="text-wrap mt-1"
                        >Copier/saisir le code d’accès (Le token) qui vous a été fourni dans le champ de vérification afin d’accéder à 
                        la séance publique d’ouverture (Offres/ Propositions/ Plis)</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="7" class="align-center mx-auto my-auto">
                  <v-text-field
                    counter
                    minlength="6"
                    class="mb-2"
                    v-model="secret"
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    label="Saisir le code de vérification envoyé par mail pour accès à la séance publique d’ouverture (Offres/ Propositions/ Plis)"
                    required
                  ></v-text-field>
                  <v-btn small :block="$vuetify.breakpoint.smAndDown" class="primary my-4" @click="CheckValidity"
                    >ACCEDER A LA SEANCE PUBLIQUE D’OUVERTURE</v-btn
                  >
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  data: () => ({
    value: String,
    switch1: false,
    secret: ""
  }),
  methods: {
    ...mapActions({
      representatorCheck: "Auth/representatorCheck"
    }),
    CheckValidity() {
      const data = new FormData();
      console.log(this.secret);
      data.append("token", this.secret);
      this.representatorCheck(data);
    }
  }
};
</script>
